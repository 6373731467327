import React, { useEffect, useRef, useState } from "react"
import * as Styles from "./fadeIn.module.scss"

interface FadeInProps {
  children: React.ReactNode
  delay?: number
}

export const FadeIn: React.FC<FadeInProps> = ({ children, delay = 0 }) => {
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      "IntersectionObserver" in window &&
      ref.current
    ) {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              // 少し遅延させることで、アニメーションが滑らかになります
              setTimeout(() => {
                setIsVisible(true)
              }, delay)
              observer.unobserve(entry.target)
            }
          })
        },
        {
          threshold: 0.1,
          rootMargin: "0px 0px -100px 0px",
        }
      )

      observer.observe(ref.current)

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current)
        }
      }
    }
  }, [delay])

  return (
    <div
      ref={ref}
      className={`${Styles.fadeElement} ${isVisible ? Styles.visible : ""}`}
    >
      {children}
    </div>
  )
}
