import React from "react"

import { Layout } from "../components/layout"
import SEO from "../components/seo"
import * as Styles from "./index.module.scss"
import { FadeIn } from "../components/fadeIn"
import { LogoScroller } from "../components/logoScroller"
import { WorksSlider } from "../components/worksSlider"
import { Form } from "@/components/form"

// 新規追加の画像インポート
import logo01 from "../images/top/logo_01.png"
import logo02 from "../images/top/logo_02.png"
import logo03 from "../images/top/logo_03.png"
import logo04 from "../images/top/logo_04.png"
import logo05 from "../images/top/logo_05.png"
import logo06 from "../images/top/logo_06.png"
import logo07 from "../images/top/logo_07.png"
import logo08 from "../images/top/logo_08.png"
import logo09 from "../images/top/logo_09.png"
import logo10 from "../images/top/logo_10.png"
import logo11 from "../images/top/logo_11.png"
import logo12 from "../images/top/logo_12.png"
import logo13 from "../images/top/logo_13.png"
import logo14 from "../images/top/logo_14.png"
import logo15 from "../images/top/logo_15.png"
import imgPC01 from "../images/top/img01__pc.svg"
import imgSP01 from "../images/top/img01__sp.svg"
import imgPC02 from "../images/top/img02__pc.svg"
import imgSP02 from "../images/top/img02__sp.svg"
import img03 from "../images/top/img03.png"
import img04 from "../images/top/img04.png"
import img05 from "../images/top/img05.png"
import imgPC06 from "../images/top/img06__pc.svg"
import imgSP06 from "../images/top/img06__sp.svg"
import imgPC07 from "../images/top/img07__pc.svg"
import imgSP07 from "../images/top/img07__sp.svg"
import works01 from "../images/top/works_01.jpg"
import works02 from "../images/top/works_02.png"
import works03 from "../images/top/works_03.png"
import works04 from "../images/top/works_04.png"
import works05 from "../images/top/works_05.png"
import member01 from "../images/top/member_01.jpg"
import member02 from "../images/top/member_02.jpg"
import member03 from "../images/top/member_03.jpg"
import member04 from "../images/top/member_04.jpg"
import tamkun from "../images/top/tamkun.svg"

const TopPage: React.VFC = () => {
  // スムーズスクロール用の関数を追加
  const scrollToContact = (e: React.MouseEvent) => {
    e.preventDefault()
    const contactElement = document.getElementById("contact")

    if (contactElement) {
      const headerHeight = 80 // ヘッダーの高さ（実際の高さに合わせて調整）
      const currentPosition = window.scrollY
      const targetTop = contactElement.getBoundingClientRect().top
      const scrollPosition = currentPosition + targetTop - headerHeight - 40 // 追加のオフセット

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      })

      // URLにハッシュを追加（オプション）
      if (window.history && window.history.pushState) {
        window.history.pushState(null, "", "#contact")
      }
    }
  }

  return (
    <>
      <SEO title="ECサイトのカート選定から構築・プロモーションまで | TAM" />
      <Layout>
        <main className={Styles.pOhtam}>
          <div className={Styles.pOhtamHero}>
            <div className={Styles.pOhtamInner}>
              <FadeIn>
                <div className={Styles.pOhtamHeroTxtBlock}>
                  <p className={Styles.pOhtamHeroTxt1}>一社一社、最適戦略</p>
                  <p className={Styles.pOhtamHeroTxt2}>
                    案件0でも大丈夫。
                    <br />
                    ECのご相談はTAMへ
                  </p>
                  <p className={Styles.pOhtamHeroTxt1}>
                    深く関わり、
                    <br />
                    必要な支援だけを。
                  </p>
                </div>
              </FadeIn>

              <FadeIn>
                <ul className={`${Styles.pOhtamHeroBtns}`}>
                  <li>
                    <a
                      href="#contact"
                      className={`${Styles.pOhtamBtn} ${Styles.style1}`}
                      onClick={scrollToContact}
                    >
                      <span>お問い合わせ</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/information/brochure.pdf"
                      className={`${Styles.pOhtamBtn} ${Styles.style2}`}
                      target="_blank"
                    >
                      <span>
                        資料ダウンロード<small>※問い合わせ不要</small>
                      </span>
                    </a>
                  </li>
                </ul>
              </FadeIn>
            </div>
            <FadeIn>
              <picture
                className={`${Styles.pOhtamHeroTamkun} ${Styles.uOnlyPc}`}
              >
                <img
                  src={tamkun}
                  alt="TAMくんイラスト"
                  width="106"
                  height="185"
                  loading="lazy"
                />
              </picture>
            </FadeIn>
          </div>

          <div className={Styles.pOhtamLogos}>
            <LogoScroller direction="ltr">
              <img
                src={logo01}
                loading="lazy"
                alt="Belle Maisonのロゴ"
                width="140"
                height="60"
              />
              <img
                src={logo02}
                loading="lazy"
                alt="大丸松坂屋ONLINE STOREのロゴ"
                width="140"
                height="60"
              />
              <img
                src={logo03}
                loading="lazy"
                alt="コーナンe-ショップのロゴ"
                width="140"
                height="60"
              />
              <img
                src={logo04}
                loading="lazy"
                alt="HIMARAYAのロゴ"
                width="140"
                height="60"
              />
              <img
                src={logo05}
                loading="lazy"
                alt="Heart Plusのロゴ"
                width="140"
                height="60"
              />
              <img
                src={logo06}
                loading="lazy"
                alt="洋服の青山のロゴ"
                width="140"
                height="60"
              />
              <img
                src={logo07}
                loading="lazy"
                alt="THE SUIT COMPANYのロゴ"
                width="140"
                height="60"
              />
              <img
                src={logo08}
                loading="lazy"
                alt="hare:kariのロゴ"
                width="140"
                height="60"
              />
            </LogoScroller>

            <LogoScroller direction="rtl">
              <img
                src={logo09}
                loading="lazy"
                alt="Calbeeのロゴ"
                width="140"
                height="60"
              />
              <img
                src={logo10}
                loading="lazy"
                alt="INEのロゴ"
                width="140"
                height="60"
              />
              <img
                src={logo11}
                loading="lazy"
                alt="DAYTONAのロゴ"
                width="140"
                height="60"
              />
              <img
                src={logo12}
                loading="lazy"
                alt="SHIMA SEIKIのロゴ"
                width="140"
                height="60"
              />
              <img
                src={logo13}
                loading="lazy"
                alt="BRUNCH PARKのロゴ"
                width="140"
                height="60"
              />
              <img
                src={logo14}
                loading="lazy"
                alt="azabu tailorのロゴ"
                width="140"
                height="60"
              />
              <img
                src={logo15}
                loading="lazy"
                alt="UNITED ARROWS LTD.のロゴ"
                width="140"
                height="60"
              />
            </LogoScroller>
          </div>
          <FadeIn>
            <section className={Styles.pOhtamSec1}>
              <div className={Styles.pOhtamInner}>
                <div className={Styles.pOhtamLayout1}>
                  <div className={Styles.pOhtamLayoutTxtBlock}>
                    <h2 className={Styles.pOhtamTtl1}>TAMの強み</h2>

                    <p className={Styles.pOhtamCatch1}>
                      共創パートナーとして
                      <br />
                      戦略立案から実行まで
                      <br />
                      トータルプロデュース
                    </p>

                    <p className={Styles.pOhtamTxt1}>
                      TAM One Brand
                      として各専門チームがシームレスに連携しながら、お客さまと共に、製品・サービスの新しい価値を共創し、
                      <br className={Styles.uOnlyPc} />
                      マーケティングから現場運用まで伴走します。
                    </p>
                  </div>

                  <figure className={Styles.pOhtamLayoutImgBlock}>
                    <picture>
                      <source srcSet={imgPC01} media="(min-width: 768px)" />
                      <img
                        src={imgSP01}
                        alt="TAMの強みのイメージ"
                        width="337"
                        height="339"
                        loading="lazy"
                      />
                    </picture>
                  </figure>
                </div>
              </div>
            </section>
          </FadeIn>

          <section className={Styles.pOhtamSec2}>
            <FadeIn>
              <div className={Styles.pOhtamSec2Bg}>
                <div className={Styles.pOhtamInner}>
                  <h2 className={Styles.pOhtamTtl1}>提供サービス</h2>

                  <p className={Styles.pOhtamTxt1}>
                    EC・マーケティングのあらゆる課題に総合的にアプローチします。
                  </p>

                  <div className={Styles.pOhtamLayout2}>
                    <figure className={Styles.pOhtamLayout2ImgBlock}>
                      <picture>
                        <source srcSet={imgPC02} media="(min-width: 768px)" />
                        <img
                          src={imgSP02}
                          alt="提供サービスのイメージ"
                          width="345"
                          height="328"
                          loading="lazy"
                        />
                      </picture>
                    </figure>

                    <div className={Styles.pOhtamLayout2TxtBlock}>
                      <dl className={Styles.pOhtamService}>
                        <dt className={Styles.pOhtamServiceTitle}>
                          <span>1</span>
                          <span>ECプロデュース・構築支援</span>
                        </dt>
                        <dd className={Styles.pOhtamServiceContent}>
                          <p className={Styles.pOhtamTxt2}>
                            事業ビジョン、ユーザーインサイトから課題・戦略を整理。
                            <br className={Styles.uOnlyPc} />
                            上流戦略からEC構築まで伴走し、本質的な成果を導きます。
                          </p>
                        </dd>

                        <dt className={Styles.pOhtamServiceTitle}>
                          <span>2</span>
                          <span>UI / UX・CRO支援</span>
                        </dt>
                        <dd className={Styles.pOhtamServiceContent}>
                          <p className={Styles.pOhtamTxt2}>
                            幅広いアプローチを通じてECサイト内での販促効率やUXを最適化し、売上拡大や顧客醸成を実現するオンラインストア運用をサポートします。
                          </p>
                        </dd>

                        <dt className={Styles.pOhtamServiceTitle}>
                          <span>3</span>
                          <span>D2Cグロース支援</span>
                        </dt>
                        <dd className={Styles.pOhtamServiceContent}>
                          <p className={Styles.pOhtamTxt2}>
                            デジタル施策を中心に、認知・集客から購入、ファン化までを包括的に戦略立案し、その実践まで伴走します。
                          </p>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </FadeIn>
            <FadeIn>
              <div className={Styles.pOhtamSec3}>
                <div className={Styles.pOhtamInner}>
                  <h3 className={Styles.pOhtamTtl2}>
                    <span>1</span>
                    <span>
                      ECプロデュース・
                      <br className={Styles.uOnlySp} />
                      構築支援
                    </span>
                  </h3>

                  <p className={Styles.pOhtamTxt1}>
                    事業ビジョン、ユーザーインサイトから課題・戦略を整理。上流戦略からEC構築まで伴走し、本質的な成果を導きます。
                  </p>

                  <ul className={Styles.pOhtamList1}>
                    <li>
                      <figure className={Styles.pOhtamList1Img}>
                        <img
                          src={img03}
                          alt="プロデュースのイメージ"
                          width="335"
                          height="240"
                          loading="lazy"
                        />
                      </figure>
                      <div className={Styles.pOhtamList1Inner}>
                        <h4 className={Styles.pOhtamTtl3}>プロデュース</h4>
                        <p className={Styles.pOhtamTxt1}>
                          ビジネス戦略・デジタル戦略を落とし込んだECサイトを、共創型ワークショップを通じてご提案します。
                        </p>
                      </div>
                    </li>
                    <li>
                      <figure className={Styles.pOhtamList1Img}>
                        <img
                          src={img04}
                          alt="カート選定・要件定義のイメージ"
                          width="335"
                          height="240"
                          loading="lazy"
                        />
                      </figure>
                      <div className={Styles.pOhtamList1Inner}>
                        <h4 className={Styles.pOhtamTtl3}>
                          カート選定・要件定義
                        </h4>
                        <p className={Styles.pOhtamTxt1}>
                          ビジネススキームや要件に最適なカートサービスをご提案します。既存カートを活用した実装も可能な限り対応します。
                        </p>
                      </div>
                    </li>
                    <li>
                      <figure className={Styles.pOhtamList1Img}>
                        <img
                          src={img05}
                          alt="構築・リリースのイメージ"
                          width="335"
                          height="240"
                          loading="lazy"
                        />
                      </figure>
                      <div className={Styles.pOhtamList1Inner}>
                        <h4 className={Styles.pOhtamTtl3}>構築・リリース</h4>
                        <p className={Styles.pOhtamTxt1}>
                          プランニングした戦略を実現へ導きます。
                          <br className={Styles.uOnlyPc} />
                          大規模サイトの構築実績が多数ございます。
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </FadeIn>
            <FadeIn>
              <div className={Styles.pOhtamSec2Bg}>
                <div className={Styles.pOhtamInner}>
                  <h3 className={Styles.pOhtamTtl2}>
                    <span>2</span>
                    <span>UI/UX・CRO</span>
                  </h3>

                  <p className={Styles.pOhtamTxt1}>
                    幅広いアプローチを通じてECサイト内での販促効率やUXを最適化し、売上拡大や顧客醸成を実現するオンラインストア運用をサポートします。
                  </p>

                  <figure className={`${Styles.pOhtamImg1} ${Styles.size1}`}>
                    <picture>
                      <source srcSet={imgPC06} media="(min-width: 768px)" />
                      <img
                        src={imgSP06}
                        alt="UI/UX・CROのイメージ"
                        width="345"
                        height="621"
                        loading="lazy"
                      />
                    </picture>
                  </figure>
                </div>
              </div>
            </FadeIn>
            <FadeIn>
              <div className={Styles.pOhtamSec3}>
                <div className={Styles.pOhtamInner}>
                  <h3 className={Styles.pOhtamTtl2}>
                    <span>3</span>
                    <span>D2Cグロース支援</span>
                  </h3>

                  <p className={Styles.pOhtamTxt1}>
                    デジタル施策を中心に、認知・集客から購入、ファン化までを包括的に戦略立案し、その実践まで伴走します。
                  </p>

                  <figure className={`${Styles.pOhtamImg1} ${Styles.size2}`}>
                    <picture>
                      <source srcSet={imgPC07} media="(min-width: 768px)" />
                      <img
                        src={imgSP07}
                        alt="D2Cグロース支援のイメージ"
                        width="375"
                        height="1125"
                        loading="lazy"
                      />
                    </picture>
                  </figure>

                  <p className={Styles.pOhtamCatch2}>
                    このほかにも、お客様に寄り添った柔軟なサポートを提供しています。
                  </p>
                </div>
              </div>
            </FadeIn>
          </section>
          <FadeIn>
            <section className={Styles.pOhtamSec4}>
              <div className={Styles.pOhtamInner}>
                <h2 className={Styles.pOhtamTtl1}>事例紹介</h2>

                <div className={Styles.pOhtamWorks}>
                  <WorksSlider>
                    <div className={Styles.pOhtamWorksCard}>
                      <div className={Styles.pOhtamWorksCardInner}>
                        <figure className={Styles.pOhtamWorksCardImg}>
                          <img
                            src={works01}
                            alt="青山商事の事例画像"
                            width="165"
                            height="295"
                            loading="lazy"
                          />
                        </figure>

                        <div className={Styles.pOhtamWorksCardBody}>
                          <p className={Styles.pOhtamWorksCardCatch}>
                            紳⼠服業界最⼤⼿の
                            <br />
                            「店舗が強い」ビジネスにおける
                            <br />
                            理想のECづくりをサポート
                          </p>

                          <h3 className={Styles.pOhtamWorksCardClient}>
                            <span className={Styles.pOhtamWorksCardClientName}>
                              ⻘⼭商事株式会社様
                            </span>
                            <span className={Styles.pOhtamWorksCardClientWork}>
                              洋服の⻘⼭オンラインストア
                            </span>
                            <span className={Styles.pOhtamWorksCardClientType}>
                              ECアーキテクト / UI / UX・CRO
                            </span>
                          </h3>

                          <p className={Styles.pOhtamTxt2}>
                            品質を伝えていくブランドサイトとしての役割を強化しながら、ECもデジタル・リアル一環の購買体験の中の「一部」として機能することをゴールに設定。UI・機能改訂・商品の良さをWEB上で表現することを軸にした購買体験のリブランディングを通して、洋服の青山全体の売り上げアップを目指しました。
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* 2枚目: デイトナ様 */}
                    <div className={Styles.pOhtamWorksCard}>
                      <div className={Styles.pOhtamWorksCardInner}>
                        <figure className={Styles.pOhtamWorksCardImg}>
                          <img
                            src={works02}
                            alt="デイトナの事例画像"
                            width="165"
                            height="295"
                            loading="lazy"
                          />
                        </figure>

                        <div className={Styles.pOhtamWorksCardBody}>
                          <p className={Styles.pOhtamWorksCardCatch}>
                            D2Cブランドのグロースを
                            <br />
                            トータル支援
                          </p>

                          <h3 className={Styles.pOhtamWorksCardClient}>
                            <span className={Styles.pOhtamWorksCardClientName}>
                              株式会社デイトナ様
                            </span>
                            <span className={Styles.pOhtamWorksCardClientWork}>
                              DAYTONA OUTDOORS
                            </span>
                            <span className={Styles.pOhtamWorksCardClientType}>
                              ECアーキテクト / UI / UX・CRO / D2Cグロース支援
                            </span>
                          </h3>

                          <p className={Styles.pOhtamTxt2}>
                            アウトドア事業のリブランディングとデジタル戦略を包括的にサポート。広告やSNSを活用した集客施策に加え、購入体験の改善、LINEを活用したCRM設計、リアルイベントとの連携キャンペーンやモール施策まで、マーケティング全般を推進。
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* 3枚目: ユナイテッドアローズ様 */}
                    {/* <div className={Styles.pOhtamWorksCard}>
                      <div className={Styles.pOhtamWorksCardInner}>
                        <figure className={Styles.pOhtamWorksCardImg}>
                          <img
                            src={works03}
                            alt="ユナイテッドアローズの事例画像"
                            width="165"
                            height="295"
                            loading="lazy"
                          />
                        </figure>

                        <div className={Styles.pOhtamWorksCardBody}>
                          <p className={Styles.pOhtamWorksCardCatch}>
                            データドリブン×ブランド体験を
                            <br />
                            融合させた
                            <br />
                            ECサイトのUX / UI設計
                          </p>

                          <h3 className={Styles.pOhtamWorksCardClient}>
                            <span className={Styles.pOhtamWorksCardClientName}>
                              株式会社ユナイテッドアローズ様
                            </span>
                            <span className={Styles.pOhtamWorksCardClientWork}>
                              ユナイテッドアローズ公式通販
                            </span>
                            <span className={Styles.pOhtamWorksCardClientType}>
                              UI / UX・CRO
                            </span>
                          </h3>

                          <p className={Styles.pOhtamTxt2}>
                            機能的なユーザビリティとブランド体験を融合したUX/UI設計を実施。GAデータやAI解析、ABテストを活用しUI設計を推進。ブランドの世界観を重視し、独自性を活かした柔軟なデザインを採用。KPI設定から効果検証までを継続的に行うPDCA運用体制を構築。データドリブンアプローチとデザイン戦略を統合し、ブランド価値とユーザー体験を高める成果を出した。
                          </p>
                        </div>
                      </div>
                    </div> */}

                    {/* 4枚目: カルビー様 */}
                    {/* <div className={Styles.pOhtamWorksCard}>
                      <div className={Styles.pOhtamWorksCardInner}>
                        <figure className={Styles.pOhtamWorksCardImg}>
                          <img
                            src={works04}
                            alt="カルビーの事例画像"
                            width="165"
                            height="295"
                            loading="lazy"
                          />
                        </figure>

                        <div className={Styles.pOhtamWorksCardBody}>
                          <p className={Styles.pOhtamWorksCardCatch}>
                            D2CビジネスにおけるCRO戦略と
                            <br />
                            クリエイティブ制作をリード
                          </p>

                          <h3 className={Styles.pOhtamWorksCardClient}>
                            <span className={Styles.pOhtamWorksCardClientName}>
                              カルビー株式会社様
                            </span>
                            <span className={Styles.pOhtamWorksCardClientWork}>
                              新規D2C事業 Body Granola
                            </span>
                            <span className={Styles.pOhtamWorksCardClientType}>
                              UI / UX・CRO / D2Cグロース支援
                            </span>
                          </h3>

                          <p className={Styles.pOhtamTxt2}>
                            CRO戦略支援とクリエイティブ制作を担当。クリエイティブ領域ではPDCA運用の中で100種類以上の広告バナーを戦略的に制作。また、ランディングページやECサイトの改善ではユーザーデータを基に高価格帯商品に信頼感を与える設計に。現在は、LTV最適化やecforce開発支援まで対応スコープを拡大し、柔軟かつ包括的なサポートで事業成果の最大化を支援している。
                          </p>
                        </div>
                      </div>
                    </div> */}

                    {/* 5枚目: 青山商事様のhare:kari */}
                    <div className={Styles.pOhtamWorksCard}>
                      <div className={Styles.pOhtamWorksCardInner}>
                        <figure className={Styles.pOhtamWorksCardImg}>
                          <img
                            src={works05}
                            alt="hare:kariの事例画像"
                            width="165"
                            height="295"
                            loading="lazy"
                          />
                        </figure>

                        <div className={Styles.pOhtamWorksCardBody}>
                          <p className={Styles.pOhtamWorksCardCatch}>
                            レンタル事業を一新する包括支援で 顧客体験を高める
                          </p>

                          <h3 className={Styles.pOhtamWorksCardClient}>
                            <span className={Styles.pOhtamWorksCardClientName}>
                              青山商事株式会社様
                            </span>
                            <span className={Styles.pOhtamWorksCardClientWork}>
                              レンタルサービス hare:kari
                            </span>
                            <span className={Styles.pOhtamWorksCardClientType}>
                              ECアーキテクト / UI / UX・CRO / D2Cグロース支援
                            </span>
                          </h3>

                          <p className={Styles.pOhtamTxt2}>
                            レンタル事業のリブランディングとマーケティング運用を包括的にサポート。店舗の空間設計や販促物の制作に加え、SNSや広告などのプロモーション、サイト設計やSEOコンテンツまで、ユーザー体験に関わる部分を多面的にご支援。
                          </p>
                        </div>
                      </div>
                    </div>
                  </WorksSlider>
                </div>
              </div>
            </section>
          </FadeIn>

          <FadeIn>
            <section className={Styles.pOhtamSec5}>
              <div className={Styles.pOhtamInner}>
                <h2 className={Styles.pOhtamTtl1}>ECチームメンバー</h2>

                <p className={Styles.pOhtamCatch3}>
                  プランニング、
                  <br className={Styles.uOnlySp} />
                  テクノロジーを支える
                  <br />
                  TAM ECチームのPEOPLE。
                </p>

                <ul className={Styles.pOhtamMembers}>
                  <li className={Styles.pOhtamMembersCard1}>
                    <div className={Styles.pOhtamMembersHead}>
                      <figure className={Styles.pOhtamMembersImg}>
                        <img
                          src={member01}
                          alt="引野 ⽂也の写真"
                          width="295"
                          height="222"
                          loading="lazy"
                        />
                      </figure>
                      <p className={Styles.pOhtamMembersName}>
                        <span className={Styles.pOhtamMembersNameTtl}>
                          ECチーム 代表
                          <br className={Styles.uOnlyPc} />
                          ECプランナー
                        </span>
                        <span className={Styles.pOhtamMembersNameJp}>
                          引野 ⽂也
                        </span>
                        <span className={Styles.pOhtamMembersNameEn}>
                          Hikino Fumiya
                        </span>
                      </p>
                    </div>

                    <div className={Styles.pOhtamMembersBody}>
                      <p className={Styles.pOhtamCatch4}>
                        ECキャリア7年のエキスパート
                      </p>

                      <p className={Styles.pOhtamTxt3}>
                        学生時代、アルバイト先の飲食店でECサイトの立ち上げを経験。そこでECの可能性や楽しさを感じ、新卒でTAMにジョイン。現在はECチームの責任者として、EC領域の上流から現場支援まで幅広く従事。上流戦略のデジタル接点・ECへのアウトプットを得意とする。趣味は美味しいお酒と美味しいご飯。
                      </p>

                      <ul className={Styles.pOhtamList2}>
                        <li>50ブランド以上のECサイト支援に従事</li>
                        <li>グループ最年少で役員就任</li>
                        <li>関西EC交流会主幹</li>
                      </ul>
                    </div>
                  </li>
                  <li className={Styles.pOhtamMembersCard2}>
                    <div className={Styles.pOhtamMembersHead}>
                      <figure className={Styles.pOhtamMembersImg}>
                        <img
                          src={member02}
                          alt="米本 和生の写真"
                          width="295"
                          height="222"
                          loading="lazy"
                        />
                      </figure>
                      <p className={Styles.pOhtamMembersName}>
                        <span className={Styles.pOhtamMembersNameTtl}>
                          ECチーム 副代表 CTO
                        </span>
                        <span className={Styles.pOhtamMembersNameJp}>
                          米本 和生
                        </span>
                        <span className={Styles.pOhtamMembersNameEn}>
                          Yonemoto Kazuki
                        </span>
                      </p>
                    </div>
                  </li>
                  <li className={Styles.pOhtamMembersCard3}>
                    <h3 className={Styles.pOhtamTtl4}>アドバイザー</h3>

                    <ul className={Styles.pOhtamAdvisers}>
                      <li>
                        <figure className={Styles.pOhtamAdvisersImg}>
                          <img
                            src={member03}
                            alt="川添 隆の写真"
                            width="110"
                            height="121"
                            loading="lazy"
                          />
                        </figure>
                        <p className={Styles.pOhtamAdvisersName}>
                          <span className={Styles.pOhtamAdvisersNameTtl}>
                            Eコマース
                            <br />
                            先生
                          </span>
                          <span className={Styles.pOhtamAdvisersNameJp}>
                            川添 隆
                          </span>
                          <span className={Styles.pOhtamAdvisersNameEn}>
                            Kawazoe Takashi
                          </span>
                        </p>
                      </li>
                      <li>
                        <figure className={Styles.pOhtamAdvisersImg}>
                          <img
                            src={member04}
                            alt="中山 茂の写真"
                            width="110"
                            height="121"
                            loading="lazy"
                          />
                        </figure>
                        <p className={Styles.pOhtamAdvisersName}>
                          <span className={Styles.pOhtamAdvisersNameTtl}>
                            マーケティング
                            <br />
                            アドバイザー
                          </span>
                          <span className={Styles.pOhtamAdvisersNameJp}>
                            中山 茂
                          </span>
                          <span className={Styles.pOhtamAdvisersNameEn}>
                            Nakayama Shigeru
                          </span>
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>

                <div className={Styles.pOhtamWhatis}>
                  <div className={Styles.pOhtamWhatisImg}>
                    <img
                      src={tamkun}
                      alt="TAMのキャラクター"
                      width="142"
                      height="249"
                      loading="lazy"
                    />
                  </div>

                  <div className={Styles.pOhtamWhatisBody}>
                    <h3 className={Styles.pOhtamTtl5}>What&apos;s TAM?</h3>

                    <p className={Styles.pOhtamCatch5}>
                      専門チームがシームレスに連携し
                      <br />
                      デジタル・マーケティングのフルサービスを提供
                    </p>

                    <p className={Styles.pOhtamTxt3}>
                      プロダクションをして自分たちで「つくる」「開発する」だけでなく、
                      <br className={Styles.uOnlyPc} />
                      エージェンシーとして「つなげる」「デリバリーする」コンバージョンまで担います。
                    </p>

                    <p className={Styles.pOhtamTxt3}>
                      すべてのTAMスタッフが使うオリジナルの
                      <br className={Styles.uOnlyPc} />
                      マーケティング・フレームワーク「PGST」を活用し、
                      <br className={Styles.uOnlyPc} />
                      クライアントのビジネス目的と達成すべきKPIを共有し、戦略・戦術を共に考えビジネスゴールを目指します。
                    </p>

                    <a
                      href="https://www.tam-tam.co.jp/"
                      className={Styles.pOhtamExternal1}
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label="TAMについて詳しく知る（外部サイトが開きます）"
                    >
                      TAMについて詳しく知る
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </FadeIn>
          {/* <FadeIn>
            <section className={Styles.pOhtamSec3}>
              <div className={Styles.pOhtamInner}>
                <div className={Styles.pOhtamTtlLink}>
                  <h2 className={Styles.pOhtamTtl1}>
                    自社開発の
                    <br className={Styles.uOnlySp} />
                    Shopifyアプリ情報
                  </h2>
                  <a
                    href="/blog/shopify"
                    className={Styles.pOhtamLink}
                    aria-label="Shopifyアプリ情報をもっと見る"
                  >
                    もっと見る
                  </a>
                </div>
              </div>
            </section>
          </FadeIn> */}
        </main>
        <FadeIn>
          <Form />
        </FadeIn>
      </Layout>
    </>
  )
}

export default TopPage
