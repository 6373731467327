import React, { useEffect, useRef } from "react"
import Splide from "@splidejs/splide"
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll"
import "@splidejs/splide/dist/css/splide.min.css"

interface LogoScrollerProps {
  children: React.ReactNode
  direction?: "ltr" | "rtl" // 左から右 または 右から左
  className?: string
}

export const LogoScroller: React.FC<LogoScrollerProps> = ({
  children,
  direction = "ltr",
  className = "",
}) => {
  const splideRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (splideRef.current && typeof window !== "undefined") {
      const splide = new Splide(splideRef.current, {
        type: "loop",
        drag: false,
        focus: "center",
        perPage: 5,
        autoWidth: true,
        gap: 16,
        arrows: false,
        pagination: false,
        autoScroll: {
          speed: direction === "ltr" ? -0.5 : 0.5, // 速度を半分に減速
          pauseOnHover: false,
          pauseOnFocus: false,
        },
        breakpoints: {
          768: {
            perPage: 2,
            gap: 8,
          },
        },
      })

      splide.mount({ AutoScroll })

      return () => {
        splide.destroy()
      }
    }
  }, [direction])

  return (
    <div
      ref={splideRef}
      className={`splide ${className}`}
    >
      <div className="splide__track">
        <ul className="splide__list">
          {React.Children.map(children, child => (
            <li className="splide__slide">{child}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}
