import React, { useEffect, useRef } from "react"
import Splide from "@splidejs/splide"
import "@splidejs/splide/dist/css/splide.min.css"
import * as Styles from "./worksSlider.module.scss"

import arrowPrev from "../images/top/icon_slide_arrow_prev.png"
import arrowNext from "../images/top/icon_slide_arrow_next.png"

interface WorksSliderProps {
  children: React.ReactNode
  className?: string
}

export const WorksSlider: React.FC<WorksSliderProps> = ({
  children,
  className = "",
}) => {
  const splideRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (splideRef.current && typeof window !== "undefined") {
      const splide = new Splide(splideRef.current, {
        type: "loop",
        perPage: 1,
        padding: { left: "3.02%", right: "30%" }, // 右側に30%のパディング
        gap: 32, // カード間の余白
        arrows: true, // 矢印あり
        pagination: false, // ページネーションなし
        speed: 800, // アニメーション速度
        easing: "cubic-bezier(0.25, 1, 0.5, 1)", // イージング
        breakpoints: {
          768: {
            perPage: 1,
            padding: "8%",
            gap: 20,
          },
        },
      })

      splide.mount()

      return () => {
        splide.destroy()
      }
    }
  }, [])

  return (
    <div
      ref={splideRef}
      className={`splide ${Styles.worksSlider} ${className}`}
    >
      <div className="splide__track">
        <ul className="splide__list">
          {React.Children.map(children, child => (
            <li className="splide__slide">{child}</li>
          ))}
        </ul>
      </div>
      <div className="splide__arrows">
        <button className="splide__arrow splide__arrow--prev button prev">
          <img
            src={arrowPrev}
            alt="前へ"
            width="80"
            height="80"
            loading="lazy"
          />
        </button>
        <button className="splide__arrow splide__arrow--next button next">
          <img
            src={arrowNext}
            alt="次へ"
            width="80"
            height="80"
            loading="lazy"
          />
        </button>
      </div>
    </div>
  )
}
